import React, { useState } from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TabNav from '../../components/TabNav';
import WithSidebar from '../../components/WithSidebar';

import { theme } from '../../styles/theme';

const tabs = [
  {
    text: 'RBC',
    colour: 'red',
  },
  {
    text: 'FFP',
    colour: 'yellow',
  },
  {
    text: 'PCC',
    colour: 'green',
  },
  {
    text: 'CRYO',
    colour: 'blue',
  },
  {
    text: 'PLT',
    colour: 'purple',
  },
];

const AdultsPage = () => {
  const [currentView, setCurrentView] = useState('RBC');

  return (
    <Layout navTitle="Adults">
      <Seo title="Blood Components - Adults" />
      <WithSidebar>
        <TabNav
          label="Component choice"
          tabs={tabs}
          active={currentView}
          onClick={text => setCurrentView(text)}
        />

        <div>
          {currentView === 'RBC' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.red }}>
              <h1 className="mb-gap">Red cell concentrates</h1>
              <p>
                Dose: in the absence of active bleeding, use the minimum number
                of units to achieve a target Hb. Assume an increment of 10g/L
                per unit for an average adult.
              </p>
              <p>
                <strong>
                  In trauma/major haemorrhage scenarios: Hospitals should
                  consider using O D positive red cells for unknown adult male
                  patients
                </strong>
              </p>

              <ul>
                <li>
                  <h2>R1 Acute Bleeding</h2>
                  <p>
                    Acute blood loss with haemodynamic instability. After
                    normovolaemia has been achieved/maintained, frequent
                    measurement of Hb (including by near-patient testing) should
                    be used to guide the use of red cell transfusion
                  </p>
                </li>

                <li>
                  <h2>R2 Hb ≤70g/L stable patient</h2>
                  <p>
                    Acute anaemia. Consider an Hb threshold of 70g/L and a
                    target Hb of 70‑90g/L to guide red cell transfusion
                  </p>
                </li>

                <li>
                  <h2>
                    R3 Hb ≤80g/L stable patient and acute coronary syndrome
                  </h2>
                  <p>
                    Use an Hb threshold of 80g/L and a target Hb of 80‑100g/L
                  </p>
                </li>

                <li>
                  <h2>R4 Chronic transfusion dependent anaemia </h2>
                  <p>
                    Transfuse to maintain an Hb which prevents symptoms. Suggest
                    an Hb threshold of 80g/L initially and adjust as required.
                    Haemoglobinopathy patients require individualised Hb
                    thresholds
                  </p>
                </li>

                <li>
                  <h2>R5 Radiotherapy – maintain Hb ≥ 100g/L</h2>
                  <p>
                    In patients receiving radiotherapy for cervical, and
                    possibly other tumours
                  </p>
                </li>

                <li>
                  <h2>R6 Exchange transfusion</h2>
                </li>
              </ul>
            </article>
          )}

          {currentView === 'FFP' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.yellow }}>
              <h1 className="mb-gap">Fresh frozen plasma</h1>
              <p>
                Dose: 15‑20ml/kg body weight, often equivalent to 4 units in
                adults.
              </p>

              <ul>
                <li>
                  <h2>F1 Major haemorrhage</h2>
                  <p>Transfuse empirically in a 1:1 ratio with red cells.</p>
                  <p>
                    Other settings give FFP in at least a 1 unit:2 unit ratio
                    with red cells until results from coagulation monitoring are
                    available. Once bleeding is controlled, further FFP should
                    be guided by abnormalities in PT and APTT.
                  </p>
                </li>

                <li>
                  <h2>F2 PT Ratio/INR &gt;1.5 with bleeding</h2>
                  <p>
                    Clinically significant bleeding without major haemorrhage.
                    FFP required if coagulopathy. Aim for a PT and APTT ratio of
                    ≤1.5, or local protocol range for near‑patient viscoelastic
                    assays
                  </p>
                </li>

                <li>
                  <h2>F3 PT Ratio/INR &gt;1.5 and pre-procedure</h2>
                  <p>
                    Prophylactic use when coagulation results are abnormal e.g.
                    DIC and invasive procedure is planned
                  </p>
                </li>

                <li>
                  <h2>
                    F4 Liver disease with PT Ratio/INR &gt;2 and pre-procedure
                  </h2>
                  <p>
                    FFP not usually required before invasive procedure if PT
                    ratio/INR is &gt;2 and there is no significant risk of
                    bleeding
                  </p>
                </li>

                <li>
                  <h2>F5 TTP/plasma exchange</h2>
                </li>

                <li>
                  <h2>F6 Replacement of single coagulation factor</h2>
                </li>
              </ul>
            </article>
          )}

          {currentView === 'PCC' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.green }}>
              <h1 className="mb-gap">Prothrombin complex concentrate</h1>
              <p>
                Dose determined by situation and INR. Follow local guidelines.
              </p>

              <ul>
                <li>
                  <h2>PCC1 Emergency reversal of VKA for severe bleeding</h2>
                  <p>or head injury with suspected intracerebral haemorrhage</p>
                </li>

                <li>
                  <h2>PCC2 Emergency reversal of VKA pre-emergency surgery</h2>
                </li>
              </ul>
            </article>
          )}

          {currentView === 'CRYO' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.blue }}>
              <h1 className="mb-gap">Cryoprecipitate</h1>
              <p>
                Dose: 2 pooled units, equivalent to 10 individual units, will
                increase fibrinogen by approximately 1g/L in an average sized
                adult. Cryoprecipitate should be used with FFP wherever there is
                a requirement for volume, except in the rare setting of isolated
                deficiency of fibrinogen.
              </p>

              <ul>
                <li>
                  <h2>
                    C1 Clinically significant bleeding and fibrinogen &lt;1.5g/L
                    (&lt;2g/L in obstetric bleeding)
                  </h2>
                </li>

                <li>
                  <h2>
                    C2 Fibrinogen &lt;1g/L and pre-procedure, with a risk of
                    bleeding
                  </h2>
                </li>

                <li>
                  <h2>C3 Bleeding associated with thrombolytic therapy</h2>
                </li>

                <li>
                  <h2>
                    C4 Inherited hypofibrinogenaemia, fibrinogen concentrate not
                    available
                  </h2>
                </li>
              </ul>
            </article>
          )}

          {currentView === 'PLT' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.purple }}>
              <h1 className="mb-gap">Platelet concentrates</h1>
              <p>
                Dose: for prophylaxis, do not routinely transfuse more than 1
                adult therapeutic dose. Prior to invasive procedure/to treat
                bleeding, consider patient size, previous increments and target
                count.
              </p>
              <h2>Prophylactic platelet transfusion</h2>

              <ul>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P1 Plt &lt;10 x 10{'\u2079'}/L reversible bone marrow
                      failure
                    </strong>
                  </p>
                  <p>
                    Not indicated in chronic bone marrow failure if not on
                    intensive treatment, and not bleeding.
                  </p>
                </li>

                <li>
                  <p className="fz-h3">
                    <strong>
                      P2 Plt 10 – 20 x 10{'\u2079'}/L with sepsis/haemostatic
                      abnormality, or other additional risk factor for bleeding
                    </strong>
                  </p>
                </li>
              </ul>
              <h2>Prior to invasive procedure or surgery:</h2>
              <p>To prevent bleeding associated with invasive procedures</p>
              <ul>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P3a Plt ≤20 x 10{'\u2079'}/L central venous line
                    </strong>
                  </p>
                </li>

                <li>
                  <p className="fz-h3">
                    <strong>
                      P3b Plt ≤40 x 10{'\u2079'}/L pre lumbar puncture/spinal
                      anaesthesia
                    </strong>
                  </p>
                </li>

                <li>
                  <p className="fz-h3">
                    <strong>
                      P3c Plt ≤50 x 10{'\u2079'}/L pre-percutaneous liver
                      biopsy/major surgery
                    </strong>
                  </p>
                </li>

                <li>
                  <p className="fz-h3">
                    <strong>
                      P3d Plt ≤80 x 10{'\u2079'}/L epidural anaesthesia
                    </strong>
                  </p>
                </li>

                <li>
                  <p className="fz-h3">
                    <strong>
                      P3e Plt ≤100 x 10{'\u2079'}/L pre critical site surgery
                      e.g. CNS/eye
                    </strong>
                  </p>
                </li>
              </ul>
              <p>Transfusion prior to bone marrow biopsy is not required.</p>

              <h2>
                Therapeutic use to treat bleeding (WHO bleeding grade 2 or
                above)
              </h2>
              <ul>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P4a Plt &lt;50 x 10{'\u2079'}/L – major haemorrhage
                    </strong>
                  </p>
                </li>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P4b Empirically in a major haemorrhage pack/protocol
                    </strong>
                  </p>
                </li>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P4c Plt &lt;100 x 10{'\u2079'}/L – Critical site bleeding
                      e.g. CNS
                    </strong>
                  </p>
                </li>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P4d Plt &lt;30 x 10{'\u2079'}/L – Clinically significant
                      bleeding
                    </strong>
                  </p>
                </li>
              </ul>

              <h2>Specific clinical conditions</h2>
              <ul>
                <li>
                  <p className="fz-h3">
                    <strong>P5a DIC pre procedure or if bleeding</strong>
                  </p>
                </li>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P5b Immune thrombocytopenia (emergency
                      pre-procedure/severe bleeding)
                    </strong>
                  </p>
                </li>
              </ul>

              <h2>Platelet dysfunction</h2>
              <ul>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P6a Consider if critical bleeding on anti-platelet
                      medication
                    </strong>
                  </p>
                </li>
                <li>
                  <p className="fz-h3">
                    <strong>
                      P6b Inherited platelet disorders directed by haemostasis
                      specialist
                    </strong>
                  </p>
                </li>
              </ul>
            </article>
          )}
        </div>
      </WithSidebar>
    </Layout>
  );
};

export default AdultsPage;
